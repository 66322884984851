import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Banner from '../banner';
import Sponsors from '../sponsors';
import About from '../about';
import Card from '../card';
import Registration from '../registration';

import climbingIcon from '../../assets/img/climbing.png';
import flame from '../../assets/img/flame.png';
import dayIcon from '../../assets/img/day.png';
import slacklineIcon from '../../assets/img/slackline.png';
import workshopsIcon from '../../assets/img/workshops.png';
import roadmap from '../../assets/img/roadmap.png';
import style from './style.module.scss';
import Loader from '../loader';

const Fest = () => {

    // const thursday = [
    //     { id: 1, title: 'Open Highline', time: '08°° - 21°°'},
    //     { id: 2, title: 'Open Climbing Routes', time: '08°° - 21°°'},
    //     { id: 3, title: 'Plastic', time: '16°° - 18°°'},
    // ];

    const friday = [
        { id: 1, title: 'Open Highline', time: '08°° - 21°°'},
        { id: 2, title: 'Open Climbing Routes', time: '08°° - 21°°'},
        { id: 3, title: 'Aerial Silk', time: '16°° - 17³°'},
        { id: 4, title: 'Poi Poi', time: '16³° - 17³°'},
        { id: 5, title: 'Climbing (Children)', time: '17°° - 18³°'},
        { id: 6, title: 'Recycle Plastic', time: '17³° - 20°°'},
        { id: 7, title: 'Slackline', time: '17³° - 18³°'},
        { id: 8, title: 'Yoga', time: '18°° - 19°°'},
        { id: 9, title: 'Climbing (Adult)', time: '18³° - 20°°'}
    ];

    const saturday = [
        { id: 1, title: 'Open Highline', time: '08°° - 21°°'},
        { id: 2, title: 'Open Climbing Routes', time: '08°° - 21°°'},
        { id: 3, title: 'Yoga', time: '09³° - 10³°'},
        { id: 4, title: 'Slackline', time: '10°° - 11³°'},
        { id: 5, title: 'Vertical Dance', time: '10°° - 12°°'},
        { id: 6, title: 'Lunch break', time: '12°° - 14°°'},
        { id: 7, title: 'Poi Poi', time: '14°° - 15³°'},
        { id: 8, title: 'Juggling', time: '14°° - 15³°'},
        { id: 9, title: 'Aerial Silk (Children)', time: '15°° - 16°°'},
        { id: 10, title: 'Rope Dart', time: '15³° - 17°°'},
        { id: 11, title: 'Aerial silk (Adults)', time: '16°° - 18°°'},
        { id: 12, title: 'Mandala', time: '16³° - 18°°'},
        { id: 13, title: 'Slackline', time: '18°° - 19³°'},
        { id: 14, title: 'Dragon Staff', time: '18³° - 20°°'},
        { id: 15, title: 'Open Stage', time: '20³° - 21³°'},
        { id: 16, title: 'Fire performance', time: '21³°'}
    ];

    const sunday = [
        { id: 1, title: 'Open Highline', time: '08°° - 21°°'},
        { id: 2, title: 'Open Climbing Routes', time: '08°° - 21°°'},
        { id: 3, title: 'Yoga', time: '09³° - 10³°'},
        { id: 4, title: 'Speleology', time: '10°° - 12°°'},
        { id: 5, title: 'Slackline', time: '10³° - 12°°'},
        { id: 6, title: 'Rainbow colours project performance', time: '10³° - 12°°'},
        { id: 7, title: 'Lunch break', time: '12°° - 13°°'},
        { id: 8, title: 'Mandala', time: '13°° - 14³°'},
        { id: 9, title: 'Dragon Staff', time: '13³° - 15°°'},
    ];

    const renderData = data => data.map(day => {
        return (
            <VerticalTimelineElement
                key={day.id}
                style={{ margin: '2em 0' }}
                contentStyle={{ background: 'transparent', color: '#fff', padding: 0, boxShadow: 'none' }}
                contentArrowStyle={{ borderRight: 'none' }}
                date={ day.time }
                iconClassName={style.verticalTimeline_icon}
            >
                <h3 className={style.verticalTimeline_element} style={{ textAlign: day.id % 2 ? 'right': 'left'}}>{day.title}</h3>
            </VerticalTimelineElement>
        );
    });

    return (
        <div>
            <Banner counter={true} title='Together we grow Festival'/>
            <Sponsors />
            <About />
            <div className={style.sectors}>
                <Card icon={dayIcon} number='4' title='Days' />
                <Card icon={slacklineIcon} number='6' title='Highlines' />
                <Card icon={workshopsIcon} number='15' title='Workshops' />
                <Card icon={climbingIcon} number='40' title='Climbing routes' />
            </div>
            <div className={style.program}>
                <div className={style.container}>
                    <h3>FESTIVAL <span style={{ color: '#12c990' } }>SCHEDULE</span></h3>
                    {/* <span className={style.subtitle}>Thursday</span>
                    <VerticalTimeline className={style.verticalTimeline} lineColor='#12c990'>
                        {renderData(thursday)}
                    </VerticalTimeline> */}
                    {/* <span className={style.subtitle}>Friday</span>
                    <VerticalTimeline className={style.verticalTimeline} lineColor='#12c990'>
                        {renderData(friday)}
                    </VerticalTimeline>
                    <span className={style.subtitle}>Saturday</span>
                    <VerticalTimeline className={style.verticalTimeline} lineColor='#12c990'>
                        {renderData(saturday)}
                    </VerticalTimeline>
                    <span className={style.subtitle}>Sunday</span>
                    <VerticalTimeline className={style.verticalTimeline} lineColor='#12c990'>
                        {renderData(sunday)}
                    </VerticalTimeline> */}

                    <span className={style.fancy}>Coming soon!!!</span>
                    <Loader />
                </div>
            </div>
            <div className={style.openStage}>
                <h3>OPEN STAGE <span style={{ color: '#12c990' } }>PARTICIPATION</span></h3>
                <p>
                    On Friday from 19:30h, we present to you an open stage in which you can also participate.<br/><br/>
                    You have a practiced solo act or a team performance with fire or without, contact us via email and participate.<br/><br/>
                    Contact email: <a href='mailto:cirkusnit@gmail.com'>cirkusnit@gmail.com</a><br/>
                    Contact number: <a href='tel:+385994401088'>+385 99 440 1088</a>
                </p>
            </div>
            <div className={style.contest}>
                <h3>CONTEST <span style={{ color: '#12c990' } }>PARTICIPATION</span></h3>
                <h4>Contests Overview</h4>
                <ol type="1">
                    <li>
                        <span style={{ fontWeight: '500', fontSize: '18px' }}>Most Send Lines Contest</span>
                        <p>In this contest, we have 5 highlines set up. The challenge is simple: the participant who completes the most sends across any of the lines will be crowned the winner. You can send one line multiple times or choose to send all the lines as many times as you wish—the goal is to have the highest number of sends. The top three participants will be awarded prizes for 1st, 2nd, and 3rd place.</p>
                    </li>
                    <li>
                        <span style={{ fontWeight: '500', fontSize: '18px' }}>Climbing Contest</span>
                        <p>Anyone with a festival ticket can participate in the climbing contest, which takes place at Cave Romane. The venue features 40 climbing routes. To win, you must climb the most routes, but the difficulty of the climb also matters. The winner will be the participant who climbs the most routes, with consideration given to which attempt it was (for example, the 1st try vs. the 5th try). Speed is also a factor: the faster you climb, the higher your chances of winning. Prizes will be awarded for 1st, 2nd, and 3rd place.</p>
                    </li>
                    <li>
                        <span style={{ fontWeight: '500', fontSize: '18px' }}>Freestyle Contest</span>
                        <p>The Freestyle Contest has attendee limit. Each contestant will have 2 minutes to get on the line, 10 minutes for freestyle performance, and 2 minutes to get off. There will be 2 judges who will score based on skills and tricks performed during the freestyle session. This contest focuses on creativity, technique, and style, and prizes will be given for the top three participants.</p>
                    </li>
                </ol>
                
                
                
                <h4>How to Participate</h4>
                <ul> 
                    <li>
                        <span style={{ fontWeight: '500', fontSize: '18px' }}>Most Send Lines Contest & Climbing Contest:</span>
                        <p>
                            All you need is your festival ticket! Simply show up, and at the Info Point, you'll receive instructions on how to track your progress.
                        </p>
                    </li>
                    <li>
                        <span style={{ fontWeight: '500', fontSize: '18px' }}>Freestyle Contest:</span>
                        <p>
                            To secure a spot, you must register in advance, as participation has attendee limit. We highly recommend filling out the registration form below to guarantee your place. Please note, spots may fill up quickly, and we cannot guarantee availability on the day of the festival for those who have not registered. <br/><br/>Stay updated on availability by following our FB event for live status. <a href="https://www.facebook.com/events/9423459164414358" target="_blank">Link to FB event</a>
                        </p>
                    </li>
                </ul>

                <p>*Contest participation brings prices for 1st, 2nd and 3rd place</p>
            </div>
            <div className={style.map}>
                <div className={style.container}>
                    <h3>FESTIVAL <span style={{ color: '#12c990' } }>ROADMAP</span></h3>
                    <img src={roadmap} />
                </div>
            </div>
            <Registration />
        </div>
    );
};

export default Fest;