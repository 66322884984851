import React from "react";
import { gsap } from 'gsap';
import RegisterButton from '../UI/button';
import RadioButton from '../UI/radioButton';
import applyBackground from '../../assets/img/background.png';
import check from '../../assets/svg/check.svg';
import error from '../../assets/img/error.png';

import style from './style.module.scss';
import axios from "axios";
import Modal from "../UI/modal";
import ModalIssues from "../UI/modalPaymentIssues";

const fridayWorkshops = [
    // { id: 1, title: 'Slackline', checked: false, allowed: 50},
    // { id: 2, title: 'Juggling', checked: false, allowed: 50},
    // { id: 4, title: 'Ropedart', checked: false, allowed: 20},
    // { id: 5, title: 'PoiPoi', checked: false, allowed: 20},
    // { id: 7, title: 'Yoga', checked: false, allowed: 30},
    // { id: 8, title: 'Acroyoga', checked: false, allowed: 30},
    // { id: 10, title: 'Speleologija - 17:00pm', checked: false, allowed: 30, price: 10},
    // { id: 11, title: 'Penjanje', checked: false, allowed: 25},
    // { id: 12, title: 'Mandala', checked: false, allowed: 30},
    // { id: 13, title: 'Aerial Silk (Beginner)', checked: false, allowed: 16},
    { id: 19, title: 'Vertical Dance - 10:00am', checked: false, allowed: 9, price: 10},
    { id: 21, title: 'Aerial Silk - 15:00pm', checked: false, allowed: 8},
    { id: 23, title: 'Climbing (Children) - 17:00pm', checked: false, allowed: 10, price: 10},
    { id: 25, title: 'Climbing (Adult) - 18:00pm', checked: false, allowed: 10, price: 10},
    { id: 27, title: 'Aerial Hoop - 17:00pm', checked: false, allowed: 10, price: 10}
    // { id: 18, title: 'Dragon saff - 18:00pm', checked: false, allowed: 10, price: 10},
    // { id: 30, title: 'Freestyle contest participation - 09:00am', checked: false, allowed: 20},
];

const saturdayWorkshops = [
    // { id: 1, title: 'Slackline', checked: false, allowed: 50},
    // { id: 2, title: 'Juggling', checked: false, allowed: 50},
    // { id: 4, title: 'Ropedart', checked: false, allowed: 20},
    // { id: 5, title: 'PoiPoi', checked: false, allowed: 20},
    // { id: 7, title: 'Yoga', checked: false, allowed: 30},
    // { id: 8, title: 'Acroyoga', checked: false, allowed: 30},
    { id: 20, title: 'Vertical Dance - 10:00am', checked: false, allowed: 9, price: 10},
    { id: 29, title: 'Speleologija - 10:00am', checked: false, allowed: 15, price: 10},
    // { id: 11, title: 'Penjanje', checked: false, allowed: 25},
    // { id: 12, title: 'Mandala', checked: false, allowed: 30},
    // { id: 13, title: 'Aerial Silk (Beginner)', checked: false, allowed: 16},
    { id: 22, title: 'Aerial Silk - 15:00pm', checked: false, allowed: 8},
    { id: 24, title: 'Climbing (Children) - 17:00pm', checked: false, allowed: 10, price: 10},
    { id: 26, title: 'Climbing (Adult) - 18:00pm', checked: false, allowed: 10, price: 10},
    // { id: 15, title: 'Recycle Plastic', checked: false, allowed: 20},
    { id: 28, title: 'Aerial mix (Aerial Silk, Straps, Rope) - 17:30pm', checked: false, allowed: 9, price: 10},
    { id: 31, title: 'Freestyle contest participation - 09:00am', checked: false, allowed: 20 },
];

const Registration = () => {
    const [showWorkshops, setShowWorkshops] = React.useState(false);
    const [showPayment, setShowPayment] = React.useState(false);
    const [selectedFridayWorkshops, setSelectedFridayWorkshops] = React.useState([]);
    const [selectedSaturdayWorkshops, setSelectedSaturdayWorkshops] = React.useState([]);
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [registered, setRegistered] = React.useState(false);
    const [totelPrice, setTotalPrice] = React.useState(50);
    const [payConfirmation, setPayConfirmation] = React.useState(false);
    const [payIssues, setPayIssues] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [openIssuesModal, setOpenIssuesModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (selectedFridayWorkshops.length === 0) {
            axios.get('https://together-wegrow.com/api/v1/twg_fest/workshops').then((response) => {
                const tempFridayData = response.data.map(workshop => {
                    const frontWorkshop = fridayWorkshops.find(temp => temp.id === workshop.id);
                    if (frontWorkshop) {
                        return {
                            id: workshop.id,
                            title: frontWorkshop.title,
                            checked: frontWorkshop.checked,
                            allowed: frontWorkshop.allowed,
                            price: frontWorkshop.price,
                            attending: workshop.attending
                        }
                    }
                }).filter(i => i);
                setSelectedFridayWorkshops(tempFridayData);
                const tempSaturdayData = response.data.map(workshop => {
                    const frontWorkshop = saturdayWorkshops.find(temp => temp.id === workshop.id);
                    if (frontWorkshop) {
                        return {
                            id: workshop.id,
                            title: frontWorkshop.title,
                            checked: frontWorkshop.checked,
                            allowed: frontWorkshop.allowed,
                            price: frontWorkshop.price,
                            attending: workshop.attending
                        }
                    }
                }).filter(i => i);
                setSelectedSaturdayWorkshops(tempSaturdayData);
            }
            );
        }
    }, [selectedFridayWorkshops]);

    const onWorkshopClick = (value, checked, type) => {
        if (type === 'friday') {
            const newWorkshopsData = selectedFridayWorkshops.map(workshop => {
                if (workshop.title === value) {
                    if (checked && workshop.price) setTotalPrice(totelPrice + workshop.price)
                    else if (!checked && workshop.price) setTotalPrice(totelPrice - workshop.price)
                    return {
                        ...workshop,
                        checked: checked
                    }
                }
                return workshop
            });
            setSelectedFridayWorkshops(newWorkshopsData);
        } else if (type === 'saturday') {
            const newWorkshopsData = selectedSaturdayWorkshops.map(workshop => {
                if (workshop.title === value) {
                    if (checked && workshop.price) setTotalPrice(totelPrice + workshop.price)
                    else if (!checked && workshop.price) setTotalPrice(totelPrice - workshop.price)
                    return {
                        ...workshop,
                        checked: checked
                    }
                }
                return workshop
            });
            setSelectedSaturdayWorkshops(newWorkshopsData);
        }
    }

    const handleRegistration = async () => {
        setLoading(true);
        const fridayWrsps = selectedFridayWorkshops.map(workshop => workshop.checked === true && workshop.id).filter(workshop => workshop);
        const saturdayWrsps = selectedSaturdayWorkshops.map(workshop => workshop.checked === true && workshop.id).filter(workshop => workshop);
        await axios.post('https://together-wegrow.com/api/v1/twg_fest/user', {
            firstname: firstname,
            lastname: lastname,
            email: email,
            workshops: [...fridayWrsps, ...saturdayWrsps],
            totelPrice: totelPrice,
            successfulPayment: payConfirmation ? true : false
        }).then(() => setRegistered(true))
            .catch(() => setRegistered(false))
            .finally(() => setLoading(false));
        gsap.fromTo('.arrow', {opacity: 1 , x: 0 }, { opacity: 1 , x: -400 , duration: 1 })
        gsap.fromTo('.continue', {opacity: 1 , x: 0 }, { opacity: 1 , x: -400 , duration: 1 })
        gsap.fromTo('.registertitle', {opacity: 1 , x: 0 }, { opacity: 1 , x: -500 , duration: 1 })
        gsap.fromTo('.payment', {opacity: 1, x: 0, display: 'flex'}, { opacity: 0, x: -200, display: 'none', duration: 0.5});
        gsap.fromTo('.button', { opacity: 1, display: 'block', x: 0 }, { opacity: 0, display: 'none', x: -200, duration: 0.5});
        gsap.fromTo('.thankyou', {opacity: 0, x: 200 }, { opacity: 1, display: 'flex', x: 0 , duration: 0.5, delay: 0.5 });
    };

    const onNextStepClick = async() => {
        setShowWorkshops(!showWorkshops)
        if (showWorkshops) {
            gsap.fromTo('.arrow', {opacity: 0, x: -400 }, { opacity: 1 , x: 0 , duration: 1 });
            gsap.fromTo('.workshops', {opacity: 1, x: 0 }, { opacity: 0, display: 'none', x: 200 , duration: 0.5 });
            gsap.fromTo('.detail_box_info', {opacity: 1, x: 0 }, { opacity: 0, display: 'none', x: 200 , duration: 0.5 });

            gsap.fromTo('.registration', {opacity: 0, x: -200 }, { opacity: 1, display: 'flex', x: 0 , duration: 0.5, delay: 0.5 });
            gsap.fromTo('.detail_box', {opacity: 0, x: -200 }, { opacity: 1, display: 'block', x: 0 , duration: 0.5, delay: 0.5 });
            gsap.fromTo('.continue', { opacity: 1, x: 0 }, { opacity: 0, display: 'none', x: 200, duration: 0.5 });
        } else {
            gsap.fromTo('.arrow', {opacity: 0, x: 400 }, { opacity: 1 , x: 0 , duration: 1 })
            gsap.fromTo('.registration', {opacity: 1, x: 0 }, { opacity: 0, display: 'none',x: -200 , duration: 0.5 });
            gsap.fromTo('.detail_box', {opacity: 1, x: 0 }, { opacity: 0, display: 'none',x: -200 , duration: 0.5 });

            gsap.fromTo('.workshops', {opacity: 0, x: 200 }, { opacity: 1, x: 0, display: 'flex', duration: 0.5, delay: 0.5 });
            gsap.fromTo('.detail_box_info', {opacity: 0, x: 200 }, { opacity: 1, x: 0, display: 'block', duration: 0.5, delay: 0.5 });
            gsap.fromTo('.continue', { opacity: 0, x: 200 }, { opacity: 1, display: 'flex', x: 0, duration: 1});
        }
    }

    const onContinueClick = async() => {
        setShowPayment(!showPayment);
        setShowWorkshops(!showWorkshops)
        if (showPayment) {
            gsap.fromTo('.continue', {opacity: 0, x: -400 }, { opacity: 1 , x: 0 , duration: 1 });
            gsap.fromTo('.payment', {opacity: 1, x: 0 }, { opacity: 0, display: 'none', x: 200 , duration: 0.5 });
            gsap.fromTo('.arrow', {opacity: 0, x: -400 }, { opacity: 1, position:'relative', x: 0 , duration: 1 });

            gsap.fromTo('.workshops', {opacity: 0, x: -200 }, { opacity: 1, display: 'flex', x: 0 , duration: 0.5, delay: 0.5 });
            gsap.fromTo('.button', { opacity: 1, x: 0 }, { opacity: 0, display: 'none', x: 200, duration: 1 });
        } else {
            gsap.fromTo('.continue', {opacity: 0, x: 400 }, { opacity: 1 , x: 0 , duration: 1 })
            gsap.fromTo('.workshops', {opacity: 1, x: 0 }, { opacity: 0, display: 'none',x: -200 , duration: 0.5 });
            gsap.fromTo('.arrow', {opacity: 0, x: 0 }, { opacity: 0, x: -400, position:'absolute', duration: 0.5 });

            gsap.fromTo('.payment', {opacity: 0, x: 200 }, { opacity: 1, x: 0, display: 'flex', duration: 0.5, delay: 0.5 });
            gsap.fromTo('.button', { opacity: 0, x: 200 }, { opacity: 1, display: 'block', x: 0, duration: 1});
        }
    }

    const renderWorkshops = (selectedWorkshops, type) => selectedWorkshops.map(workshop => {
        return <RadioButton
            className={[style.radiobutton, workshop.allowed > workshop.attending ? '' : style.disabled].join(' ')}
            key={workshop.id}
            id={workshop.id}
            text={workshop.allowed > workshop.attending ? workshop.title : `${workshop.title}(FILLED)`}
            checked={workshop.checked}
            onChecked={(value, checked) => onWorkshopClick(value, checked, type)}
        />
    });

    const renderPriceList = (selectedWorkshops) => selectedWorkshops.map(workshop => {
        if (workshop.checked) {
            return <div className={style.price}><p>{workshop.title}</p><span>{workshop.price ? `${workshop.price}€` : 'FREE'}</span></div>
        }
    })

    return (
        <div id='register' className={style.apply}>
            <h3>FESTIVAL <span style={{ color: '#12c990' } }>PRICELIST</span></h3>
            <div className={style.container}>
                <div className={style.container_info}>
                    <p className={style.container_info_title}>Festival ticket includes</p>
                    <div>
                        <div>
                            <p style={{ fontSize: '18px' } }>Workshops:</p>
                            <ul>
                                <li>Juggling</li>
                                <li>Poi Poi</li>
                                <li>Yoga</li>
                                <li>Rope Dart</li>
                                <li>Slackline</li>
                                <li>Bow and Arrow</li>
                                <li>Aerial Silk</li>
                                <li>Highline ground simulation</li>
                                <li>Staff</li>
                                <li>Dragon Staff</li>
                                <li>Highline access</li>
                            </ul>
                        </div>
                        <div>
                            <p style={{ fontSize: '18px' } }>Accessories:</p>
                            <ul>
                                <li>T-Shirt</li>
                                <li>Stickers</li>
                            </ul>
                        </div>
                        <div>
                            <p style={{ fontSize: '18px' } }>Contest participation:</p>
                            <ul>
                                <li>Most send highlines</li>
                                <li>Climbing</li>
                                <li>Freestyle contest</li>
                            </ul>
                        </div>
                    </div>
                    <p>*Contest participation brings prices for 1st, 2nd and 3rd place</p>
                    <p className={style.container_info_title}>Festival additional workshops</p>
                    <div>
                        <div>
                            <p style={{ fontSize: '18px' } }>Workshops:</p>
                            <ul>
                                <li>Vertical dance</li>
                                <li>Aerial Mix Workshop (Aerial Silk, Aerial Rope & Straps)</li>
                                <li>Speleology</li>
                                <li>Climbing</li>
                                <li>Aerial Hoop</li>
                            </ul>
                        </div>
                    </div>
                    <p>
                        *Additional workshops are <span style={{ fontWeight: 'bold' }}>not</span> inluded in festival ticket and are charged separately
                        <br/>
                        In order to attend additional workshops you need to make reservation using following form
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>NOTE</span>: Workshops not listed in the registration form have no attendee limit. You can join us on the day of the festival and purchase your ticket on-site.
                        If any workshops with attendee limit still have available spots by the festival day, you can register on-site as well. Stay updated on availability by following our FB event for live status.
                        However, we suggest registering through the form below to secure your spot in advance. <a href="https://www.facebook.com/events/9423459164414358" target="_blank">Link to FB event</a>
                    </p>
                </div>
                <h3>FESTIVAL <span style={{ color: '#12c990' } }>REGISTRATIONS</span></h3>
                <div className={style.apply_wrapper}>
                    <div className={style.apply_wrapper_details}>
                        <img src={applyBackground} />
                        <div className={style.apply_wrapper_details_box}>
                            <div className={style.apply_wrapper_details_title}>
                                <span style={{ color: '#12c990' } }>together</span>
                                we grow
                            </div>
                            <hr/>
                            <div className="detail_box">
                                <p>We are</p>
                                <p style={{ fontSize: '20px' } }>Inviting you to our festival!</p>
                                <ul>
                                    <li>4 Days</li>
                                    <li>6 Highlines</li>
                                    <li>15 Workshops</li>
                                    <li>Soundsystem</li>
                                    <li>T-Shirt</li>
                                </ul>
                                <p style={{ fontSize: '20px', margin: '30px 0 0' } }>Entrance:</p>
                                <ul>
                                    <li>1 day <b>20€</b>**</li>
                                    <li>Festival ticket <b>50€</b></li>
                                </ul>
                                <p>*Visitors free entrance</p>
                                <p>**Online reservations for one day are not possible</p>
                            </div>
                            <div className={[style.apply_wrapper_details_box_info, 'detail_box_info'].join(' ')}>
                                <p>Price details</p>
                                
                                {/* <p style={{ fontSize: '20px' } }>SUMMARY</p> */}
                                <div className={style.price}><p>Festival ticket</p><span>50€</span></div>
                                <ul>
                                    <li>Festival worshops (Slackline, Juggling, PoiPoi, AerialSilk, Rope Dart, Yoga, Highline ground simulation)</li>
                                    <li>Festival accessories</li>
                                    <li>Contest participation</li>
                                </ul>
                                {renderPriceList([...selectedFridayWorkshops, ...selectedSaturdayWorkshops])}
                                <hr/>
                                <div className={style.price_total}><p>TOTAL:</p><span>{totelPrice}€</span></div>
                            </div>
                        </div>
                    </div>
                    <div className={style.apply_wrapper_info}>
                        <h3 className="registertitle">Register</h3>
                        <div className={[style.apply_registration, "registration"].join(' ')}>
                            <p>Firstname</p>
                            <input className={style.apply_wrapper_info_input} type='text' placeholder='Enter your firstname' onChange={e => setFirstname(e.target.value)}/>
                            <p>Lastname</p>
                            <input className={style.apply_wrapper_info_input} type='text' placeholder='Enter your lastname' onChange={e => setLastname(e.target.value)}/>
                            <p>Email address</p>
                            <input className={style.apply_wrapper_info_input} type='text' placeholder='Enter your email' onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className={[style.apply_workshops, 'workshops'].join(' ')}>
                            <div>
                                <p>Friday</p>
                                {renderWorkshops(selectedFridayWorkshops, 'friday')}
                            </div>
                            <div>
                                <p>Saturday</p>
                                {renderWorkshops(selectedSaturdayWorkshops, 'saturday')}
                            </div>
                        </div>
                        <div className={[style.apply_payment, 'payment'].join(' ')}>
                            Payment info
                            <p>To confirm your reservation, please complete the payment via the QR code or billing info within 3 days. Your reservation will be on hold until the payment is visible in our account. Once the transaction is confirmed, you will receive your ticket via email.</p>
                            <p>Please note, when scanning the QR code for payment, ensure to manually enter the total amount to be paid, which is <span style={{ fontWeight: 'bold' }}>{totelPrice}€</span></p>
                            <RegisterButton title="Get Payment info" onClick={() => setOpenModal(!openModal)} className={style.qrcodeButton} />
                            <p style={{ display: 'flex', gap: '5px' }}><b>Payment Issues?</b> <div style={{ cursor: 'pointer' }} onClick={() => setOpenIssuesModal(!openIssuesModal)}>Click here</div></p>
                            
                            <div className={style.payment_checkbox}>
                                <input id="payment" type="checkbox" checked={payConfirmation} onChange={() => {
                                    setPayIssues(false);
                                    setPayConfirmation(!payConfirmation);
                                }}/>
                                <label for="payment">I have completed the payment</label>
                            </div>
                            <div className={style.payment_checkbox} style={{ marginTop: '5px', marginBottom: '20px' }}>
                                <input id="payment_problem" type="checkbox" checked={payIssues} onChange={() => {
                                    setPayConfirmation(false);
                                    setPayIssues(!payIssues);
                                }}/>
                                <label for="payment_problem">I was unable to make the payment</label>
                            </div>
                        </div>
                        {/* <div className={[style.apply_thankYou, 'thankyou'].join(' ')}>
                            {registered ? <img src={check} style={{ marginBottom: '2rem' }}/> : <img src={error} style={{ width: '251px' }}/>}
                            <h3>{registered ? 'Registrations closed!' : 'Oooops!'}</h3>
                            {registered
                                ? <p>Registrations for workshops are closed, dont't worry, highline and open climbing routes are still waiting for you!<br/><br/>Feel free to visit our festival and don't forget to enjoy.<br/><br/>See you soon!</p>
                                : <p>Something went wrong. Please try againg later or contact us directly via email.<br/><br/>Contact email: <a href='mailto:unitytwg@gmail.com'>unitytwg@gmail.com</a></p>
                            }
                        </div> */}
                        <div className={[style.apply_thankYou, 'thankyou'].join(' ')}>
                            {registered ? <img src={check} style={{ marginBottom: '2rem' }}/> : <img src={error} style={{ width: '251px' }}/>}
                            <h3>{registered ? 'Thank you!' : 'Oooops!'}</h3>
                            {registered
                                ? <p>You have registered for workshops successfully. We are looking forward meeting you at our festival!<br/><br/>See you soon!</p>
                                : <p>Something went wrong. Please try againg later or contact us directly via email.<br/><br/>Contact email: <a href='mailto:unitytwg@gmail.com'>unitytwg@gmail.com</a></p>
                            }
                        </div>
                        <div className={[style.apply_wrapper_info_next, (!showWorkshops && !showPayment) ? style.active : '', !showPayment ? style.active_payment : '', (firstname && lastname && email) ? '' : style.forbidden].join(' ')}>
                            {firstname && lastname && email &&
                            <>
                                <div className='arrow' onClick={() => onNextStepClick()}>
                                    <span>{showWorkshops ? 'Previous Step' : 'Next Step'}</span>
                                    <div className={[style.arrow, showWorkshops ? style.active : ''].join(' ')}></div>
                                </div>
                                <div className={['continue', style.continue].join(' ')} onClick={() => onContinueClick()}>
                                    <span>{showPayment ? 'Previous Step' : 'Next Step'}</span>
                                    <div className={[style.arrow, showPayment ? style.active : ''].join(' ')}></div>
                                </div>
                                {/* <RegisterButton className={[style.registerButton, selectedWorkshops.some(workshop => workshop.checked === true) ? '' : style.disabled].join(' ')} onClick={() => handleRegistration()} /> */}
                                <RegisterButton title={'Register'} loading={loading} className={[style.registerButton, (payConfirmation || payIssues) ? '' : style.disabled, loading ? style.disabled : ''].join(' ')} onClick={() => handleRegistration()} />
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <Modal onClick={() => setOpenModal(!openModal)} isOpen={openModal} />
            <ModalIssues onClick={() => setOpenIssuesModal(!openIssuesModal)} isOpen={openIssuesModal} />
        </div>
    );
};

export default Registration;