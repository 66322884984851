import React from 'react';
import style from './style.module.scss';

const Loader = () => {
    return (
        <div className={style.loader}>
            <div className={[style.circle, style.smallWrap, style.one].join(' ')}>
                <div className={[style.circle, style.small].join(' ')}></div>
                <div className={[style.circle, style.small].join(' ')}></div>
                <div className={[style.circle, style.small].join(' ')}></div>
                <div className={[style.circle, style.small].join(' ')}></div>
                <div className={[style.circle, style.small].join(' ')}></div>
                <div className={[style.circle, style.small].join(' ')}></div>
            </div>
            <div className={[style.circle, style.circleBlue, style.one].join(' ')}></div>
            <div className={[style.circle, style.circleYellow, style.one].join(' ')}></div>
            <div className={[style.circle ,style.arc, style.one].join(' ')}></div>
            <div className={[style.circle, style.lineWrap, style.two].join(' ')}>
                <div className={style.line}></div>
                <div className={style.line}></div>
                <div className={style.line}></div>
                <div className={style.line}></div>
                <div className={style.line}></div>
                <div className={style.line}></div>
                <div className={style.line}></div>
                <div className={style.line}></div>
            </div>
            <div className={[style.circle, style.circleThird, style.three].join(' ')}></div>
            <div className={[style.circle, style.redArc, style.three].join(' ')}></div>
            <div className={[style.circle, style.redRing].join(' ')}></div>
            <div className={[style.circle, style.circleRedCenter, style.three].join(' ')}></div>
            <div className={[style.circle, style.circleYellowBg, style.two].join(' ')}></div>
            <div className={[style.circle, style.circleRed, style.two].join(' ')}></div>
            <div className={[style.circle, style.circleYellowCenter, style.two].join(' ')}></div>
            <div className={[style.circle, style.greenArc, style.three].join(' ')}></div>
            <div className={[style.circle, style.circleFourth, style.four].join(' ')}></div>
            <div className={[style.circle, style.blueRing, style.four].join(' ')}></div>
            <div className={[style.circle, style.redRing2, style.four].join(' ')}></div>
            <div className={[style.circle, style.yellowBg2, style.four].join(' ')}></div>
            <div className={[style.circle, style.greenCenter, style.four].join(' ')}></div>
            <div className={[style.circle, style.yellowCenter2, style.four].join(' ')}></div>
            <div className={[style.circle, style.circleYellowFill, style.two].join(' ')}></div>
        </div>
    );
};

export default Loader;